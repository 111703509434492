import AgoraRTC from 'agora-rtc-react'
import { atom, getDefaultStore } from 'jotai'

const STORAGE_KEY_CHANNEL = 'agora-channel'
const STORAGE_KEY_MEETING = 'meeting-data'
const EXPIRY_TIME = 30 * 60 * 1000 // 30 min

const storage = {
  get: <T>(key: string): T | null => {
    if (typeof window === 'undefined') return null

    const stored = localStorage.getItem(key)
    if (!stored) return null

    try {
      return JSON.parse(stored) as T
    } catch {
      return null
    }
  },

  set: <T>(key: string, value: T): void => {
    if (typeof window === 'undefined') return
    localStorage.setItem(key, JSON.stringify(value))
  },

  remove: (key: string): void => {
    if (typeof window === 'undefined') return
    localStorage.removeItem(key)
  },
  clear: (): void => {
    if (typeof window === 'undefined') return
    localStorage.removeItem(STORAGE_KEY_CHANNEL)
    localStorage.removeItem(STORAGE_KEY_MEETING)
  },
}

interface StoredChannel {
  value: string
  timestamp: number
}

const getAppointmentIdFromURL = () => {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search)
    return params.get('appointmentId') || ''
  }
  return ''
}

const isRejoinAtom = atom(false)
const store = getDefaultStore()

const channelAtom = atom(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (_get) => {
    const urlValue = getAppointmentIdFromURL()

    // If URL has a value, use it and update storage
    if (urlValue && urlValue.length > 0) {
      storage.set(STORAGE_KEY_CHANNEL, {
        value: urlValue,
        timestamp: Date.now(),
      })
      return urlValue
    }

    // Check stored value and expiration
    const storedData = storage.get<StoredChannel>(STORAGE_KEY_CHANNEL)

    if (storedData && Date.now() - storedData.timestamp <= EXPIRY_TIME) {
      store.set(isRejoinAtom, true)
      return storedData.value
    } else {
      storage.remove(STORAGE_KEY_CHANNEL)
    }

    return null
  },
  (_get, _set, val: string) => {
    storage.set(STORAGE_KEY_CHANNEL, {
      value: val,
      timestamp: Date.now(),
    })
  },
)

const joinedCallAtom = atom(false)
const agoraTokenAtom = atom<string | null>(null)
const agoraClientAtom = atom(
  AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' }),
)

const randomUid = () => {
  return Math.floor(Math.random() * 10000) + 1
}

const uidAtom = atom(randomUid())
const rtcPropsAtom = atom((get) => ({
  appid: import.meta.env.AGORA_APP_ID,
  channel: get(channelAtom),
  token: get(agoraTokenAtom),
  uid: get(uidAtom),
}))

const hasMultipleCamerasAtom = atom(false)
const devicesAtom = atom<MediaDeviceInfo[]>([])

// Helper function to identify specialized back cameras
const isSpecializedBackCamera = (label: string) => {
  const specializedTerms = [
    'telephoto',
    'ultra',
    'wide',
    'triple',
    'zoom',
    'depth',
    'macro',
    'dual',
  ]
  return specializedTerms.some((term) => label.toLowerCase().includes(term))
}

// Helper function to identify basic back camera
const isBasicBackCamera = (label: string) => {
  const label_lower = label.toLowerCase()
  return (
    (label_lower.includes('back') ||
      label_lower.includes('rear') ||
      label_lower.includes('environment')) &&
    !isSpecializedBackCamera(label_lower)
  )
}

const checkForMultipleCameraAtom = atom(
  (get) => get(hasMultipleCamerasAtom),
  async (_, set) => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices()
      const videoInputDevices = devices.filter(
        (device) => device.kind === 'videoinput',
      )
      console.log('Devices:', devices)
      if (videoInputDevices.length > 1) {
        set(hasMultipleCamerasAtom, true)
        const filteredDevices = videoInputDevices.filter((device) => {
          const label = device.label.toLowerCase()
          if (label.includes('front') || label.includes('user')) {
            return true
          }
          if (isBasicBackCamera(label)) {
            return true
          }
          return false
        })
        console.log('Filtered devices:', filteredDevices)
        set(devicesAtom, filteredDevices)
      }
    } catch (e) {
      console.error('Error checking for media devices:', e)
    }
  },
)

interface Meeting {
  name?: string
  date?: string
  time?: string
}

interface StoredMeeting {
  value: Meeting
  timestamp: number
}

const getMeetingFromURL = () => {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search)
    return {
      name: params.get('name'),
      date: params.get('date'),
      time: params.get('time'),
    }
  }

  return {
    name: undefined,
    date: undefined,
    time: undefined,
  }
}

const meetingAtom = atom(
  () => {
    const urlMeeting = getMeetingFromURL()
    const hasUrlData = Object.values(urlMeeting).some((value) => Boolean(value))

    // If URL has meeting data, use it and update storage
    if (hasUrlData) {
      storage.set(STORAGE_KEY_MEETING, {
        value: urlMeeting,
        timestamp: Date.now(),
      })
      return urlMeeting
    }

    // Check stored value and expiration
    const storedData = storage.get<StoredMeeting>(STORAGE_KEY_MEETING)
    if (storedData && Date.now() - storedData.timestamp <= EXPIRY_TIME) {
      return storedData.value
    } else {
      storage.remove(STORAGE_KEY_MEETING)
    }

    // Return default empty meeting if no valid data found
    return {
      name: undefined,
      date: undefined,
      time: undefined,
    }
  },
  (_get, _set, newValue: Meeting) => {
    storage.set(STORAGE_KEY_MEETING, {
      value: newValue,
      timestamp: Date.now(),
    })
  },
)

export {
  channelAtom,
  agoraTokenAtom,
  agoraClientAtom,
  joinedCallAtom,
  rtcPropsAtom,
  uidAtom,
  checkForMultipleCameraAtom,
  devicesAtom,
  getAppointmentIdFromURL,
  meetingAtom,
  storage,
  isRejoinAtom,
}
