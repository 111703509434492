import { LoaderFunctionArgs, redirect } from 'react-router-dom'
import { getDefaultStore } from 'jotai'
import { agoraTokenAtom, uidAtom, getAppointmentIdFromURL } from '../atoms'

const backendApiUrl = import.meta.env.BACKEND_SERVICE
const store = getDefaultStore()
const urlAppointment = getAppointmentIdFromURL()

const loader = async ({ request }: LoaderFunctionArgs) => {
  if (urlAppointment && urlAppointment.length > 0) {
    const url = new URL(request.url)
    const searchParams = url.searchParams

    try {
      const response = await fetch(`${backendApiUrl}/twilio-config/token`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          appointmentCode: urlAppointment,
          uid: store.get(uidAtom),
        }),
      })

      if (!response.ok) {
        return null
      }

      const { token } = await response.json()
      store.set(agoraTokenAtom, token)
      return redirect(`/call?${searchParams.toString()}`)
    } catch (error) {
      console.log('Error fetching token:', error)
      return null
    }
  }

  return null
}

export { loader }
